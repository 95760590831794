// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/products/ProductCard.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/products/ProductCard.tsx");
  import.meta.hot.lastModified = "1733480438277.5366";
}
// REMIX HMR END

import { Link } from '@remix-run/react';
import { Price } from './Price';
export function ProductCard({
  productAsset,
  productName,
  slug,
  priceWithTax,
  currencyCode
}) {
  return <Link className="flex flex-col" prefetch="intent" to={`/products/${slug}`}>
      <img className="flex-grow object-cover aspect-[7/8]" alt="" src={productAsset?.preview} />
      <div className="h-2" />
      <div className="text-sm text-gray-700">{productName}</div>
      <div className="text-sm font-medium text-gray-900">
        <Price priceWithTax={priceWithTax} currencyCode={currencyCode} />
      </div>
    </Link>;
}
_c = ProductCard;
var _c;
$RefreshReg$(_c, "ProductCard");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;